<template>
  <div class="box sc-width">
    <div class="main-title-box">
      <div class="main-title">Sourcing Inquiry</div>
      <div class="my-sourcing">My Sourcing Inquiry</div>
    </div>

    <div class="pd-20">
      <div class="cards">
        <Spin v-if="loading" fix></Spin>
        <div class="empty" v-if="list.length === 0">
          No Data
        </div>
        <div>
          <div class="list-table">
            <div class="title">
              <div class="item product">Product Name</div>
              <div class="item quantity">Quantity</div>
              <div class="item grade">Quantity Grade</div>
              <div class="item price">Price Basics</div>
              <div class="item specs">Main Specs</div>
            </div>
            <div class="content">
              <div
                v-for="(item, index) in list"
                :key="index"
                :class="['item', { active: activeIndex === item.id }]"
                @mouseover="companyMouseOver(item.id)"
                @mouseleave="companyMouseLeave()"
              >
                <div class="top">
                  <div class="product">
                    <div class="main-name">{{ item.product_name }}</div>
                  </div>
                  <div class="quantity">{{ item.quantity }}</div>
                  <div class="grade">{{ item.quantity_grade }}</div>
                  <div class="price">{{ item.price_basics }}</div>
                  <div class="specs">{{ item.main_specs }}</div>
                </div>
                <div class="divided"></div>
              </div>
            </div>
          </div>

          <div class="page">
            <Page
              transfer
              :page-size="limit"
              :total="total"
              show-elevator
              show-total
              @on-change="pageChange"
              @on-page-size-change="pageSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
// import { uniq, trim } from "lodash";
const { _querySourcing } = api;

export default {
  name: "sourcings",
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      activeIndex: null,
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false
    };
  },
  created() {
    this.getSourcing();
  },

  methods: {
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },

    handleSearch() {
      this.page = 1;
      this.getSamples();
    },

    pageChange(page) {
      this.page = page;
      this.getSamples();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSamples();
    },
    getSourcing() {
      this.loading = true;
      this.list = [];
      _querySourcing({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;

          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
.sc-width {
  margin: 0 auto;
}
.main-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .main-title {
    font-size: 24px;

    font-weight: 700;
    color: #323538;
    line-height: 19px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
  }
}

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}

.box {
  margin-top: 206px;
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards {
    min-height: 300px;
    position: relative;
    .list-table {
      list-style: none;
      li {
        margin-bottom: 10px;
        background-color: #fff;
        border: 1px solid #f2f2f2;
        box-shadow: 0 0 4px #f8f8f8;
        .header {
          padding: 10px;
          border-bottom: 1px solid #f2f2f2;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          .wrap1 {
            min-width: 80%;
            cursor: pointer;
            text-align: left;
            .title {
              font-size: 14px;
            }
          }
          .wrap2 {
            text-align: right;
            .status {
              display: inline-block;
              background-color: #ddd;
              padding: 2px 8px;
              margin-bottom: 4px;
              &.pend {
                background-color: #ddd;
                color: #333;
              }
              &.acc {
                background-color: #d5ffd2;
                color: #418d3a;
              }
              &.rej {
                background-color: #ffddd9;
                color: #c04c3a;
              }
            }
            .time {
              color: #888;
            }
          }
        }
        .content {
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid #f2f2f2;
          .con {
            padding: 10px;
            .tit {
              color: #666;
              padding-bottom: 6px;
            }
            .tip {
              font-size: 12px;
            }
            .doc {
              color: #ff6600;
              a {
                color: #ff6600;
              }
            }
          }
          .c1 {
            cursor: pointer;
          }
          .c1,
          .c2,
          .c3 {
            flex: 0 0 20%;
          }
          .c4 {
            flex: 0 0 40%;
          }
        }
      }
      .footer {
        padding: 10px;
        text-align: right;
        .numb {
          text-align: left;
          padding: 4px 10px;
          display: inline-block;
          background-color: #ffe7d6;
        }
      }
    }
    .page {
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }

    .ivu-poptip-body {
      h4 {
        font-size: 12px;
        text-align: left;
        color: #444;
        padding: 4px 0;
      }
      .tags {
        flex-wrap: wrap;
        display: flex;
        p {
          text-align: left;
          color: #999;
          flex: 0 0 50%;
          padding: 4px 0;
        }
      }
    }
  }
}
.list-table {
  .title {
    display: flex;
    height: 42px;
    background: linear-gradient(90deg, #a25d72, #2d3b81);
    opacity: 0.9;
    border-radius: 5px;
    justify-content: space-between;
    font-size: 18px;

    font-weight: 500;
    color: #fefefe;
    line-height: 19px;
    .item {
      padding: 12px 0 0 20px;
    }
    .product {
      width: 400px;
    }
    .attendee {
      width: 300px;
    }
    .size {
      width: 300px;
    }
    .date {
      width: 200px;
    }
    .document {
      width: 300px;
    }
  }
  .content {
    /*display: flex;*/
    .item {
      height: 161px;
      padding: 0 30px;
      .top {
        display: flex;
        height: 80px;
        justify-content: space-between;
        padding-top: 30px;
        div {
          font-size: 16px;

          font-weight: 500;
          color: #323538;
          line-height: 19px;
        }
        .product {
          width: 400px;
          .main-name {
          }
          .by-name {
            font-size: 12px;

            font-weight: 400;
            color: #999999;
            line-height: 19px;
          }
        }
        .attendee {
          width: 300px;
        }
        .size {
          width: 300px;
        }
        .date {
          width: 200px;
        }
        .document {
          width: 300px;
        }
      }
      .divided {
        width: 1440px;
        height: 1px;
        background: #cccccc;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
    .active {
      box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
    }
  }
}
.status {
  /*display: inline-block;*/
  /*background-color: #ddd;*/
  /*padding: 2px 8px;*/
  /*margin-bottom: 4px;*/
  padding: 6px 0 0 6px;
  &.pend {
    width: 150px;
    height: 30px;
    background: #e6e6e6;
    border-radius: 5px;
    font-size: 14px;

    font-weight: 400;
    color: #323538;
    line-height: 18px;
  }
  &.acc {
    width: 150px;
    height: 30px;
    background: #e6f7d3;
    border-radius: 5px;
    font-size: 14px;

    font-weight: 400;
    color: #323538;
    line-height: 18px;
  }
  &.rej {
    width: 150px;
    height: 30px;
    background: #f7d3d3;
    border-radius: 5px;
    /*background-color: #ffddd9;*/
    color: #c04c3a;
    font-size: 14px;

    font-weight: 400;
    color: #323538;
    line-height: 18px;
  }
}
</style>
